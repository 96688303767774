import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf} // Update with your project image
              isBlog={false}
              title="MediNote"
              description="A user-friendly platform for efficient medical image annotation with secure login and intuitive dataset management."
              ghLink="https://github.com/yourusername/MediNote" // Update with your GitHub link
              demoLink="https://your-demo-link.com" // Update with your demo link
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion} // Update with your project image
              isBlog={false}
              title="MedLearn"
              description="A comprehensive learning platform for medical professionals, offering case studies and image annotation tools."
              ghLink="https://github.com/yourusername/MedLearn" // Update with your GitHub link
              demoLink="https://your-demo-link.com" // Update with your demo link
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor} // Update with your project image
              isBlog={false}
              title="Segmentar"
              description="An advanced image segmentation tool powered by HuNet for precise and detailed segmentation."
              ghLink="https://github.com/yourusername/Segmentar" // Update with your GitHub link
              demoLink="https://your-demo-link.com" // Update with your demo link
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify} // Update with your project image
              isBlog={false}
              title="EMR Hospital Management System"
              description="An advanced EMR system integrated with AI for automatic scheduling and forecasting."
              ghLink="https://github.com/yourusername/EMRSystem" // Update with your GitHub link
              demoLink="https://your-demo-link.com" // Update with your demo link
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Bits-0f-C0de"
              description="Personal blog page built with Next.js and Tailwind CSS, supporting dark mode and easy markdown writing."
              ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
