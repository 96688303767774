import React from 'react';
import './Test.css'; // Import the CSS file

const Test = () => {
    return (
        <div className="publication-container">
            <h1>My Publications</h1>
            <ul className="publication-list">
                <li>
                    <strong>
                        <a href="https://doi.org/10.1016/j.iswa.2024.200382" className="publication-link" target="_blank" rel="noopener noreferrer">
                            Sanjid, K. S., Junayed, M. S. S., Hossain, M. T., Wang, Y. L., Uddin, M. M., & Haque, S. A. (2024).
                        </a>
                    </strong> 
                    From pixels to pathology: A novel dual-pathway multi-scale hierarchical upsampling network for MRI-based prostate zonal segmentation. 
                    <span className="journal-name"> (Journal: Intelligent Systems with Applications, 22, 200382)</span>
                </li>
                <li>
                    <strong>
                        <a href="https://doi.org/10.1049/iet-ipr.2024.0001" className="publication-link" target="_blank" rel="noopener noreferrer">
                            Junayed, M. S. S., Sanjid, K. S., Hossain, M. T., Uddin, M. M., & Haque, S. A. (2024).
                        </a>
                    </strong> 
                    Topology‐aware anatomical segmentation of the Circle of Willis: HUNet unveils the vascular network. 
                    <span className="journal-name"> (Journal: IET Image Processing)</span>
                </li>
                <li>
                    <strong>
                        <a href="https://arxiv.org/abs/2403.17432" className="publication-link" target="_blank" rel="noopener noreferrer">
                            Sanjid, K. S., Hossain, M. T., Junayed, M. S. S., & Uddin, D. M. M. (2024).
                        </a>
                    </strong> 
                    Integrating mamba sequence model and hierarchical upsampling network for accurate semantic segmentation of multiple sclerosis legion. 
                    <span className="journal-name"> (Journal: arXiv preprint arXiv:2403.17432)</span>
                </li>
                <li>
                    <strong>
                        <a href="https://arxiv.org/abs/2404.17235" className="publication-link" target="_blank" rel="noopener noreferrer">
                            Sanjid, K. S., Hossain, T., Junayed, M. S. S., & Uddin, M. M. (2024).
                        </a>
                    </strong> 
                    Optimizing Universal Lesion Segmentation: State Space Model-Guided Hierarchical Networks with Feature Importance Adjustment. 
                    <span className="journal-name"> (Journal: arXiv preprint arXiv:2404.17235)</span>
                </li>
                <li>
                    <strong>
                        <a href="https://ieeexplore.ieee.org/document/1234567" className="publication-link" target="_blank" rel="noopener noreferrer">
                            Hossain, M. T., Junayed, M. S. S., Sanjid, K. S., Rohan, A. H., Khan, M. A., Haque, S. A., & Uddin, M. M. (2023, December).
                        </a>
                    </strong> 
                    Automated segmentation of multiple sclerosis lesions using deep learning. 
                    <span className="journal-name"> (Journal: In 2023 26th International Conference on Computer and Information Technology (ICCIT) (pp. 1-6). IEEE)</span>
                </li>
            </ul>
        </div>
    );
};

export default Test;
